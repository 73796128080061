import React from 'react'
import { makeStyles, Grid, Container, AppBar, Toolbar, Typography } from '@material-ui/core'
// import FacebookIcon from '@material-ui/icons/Facebook'
// import InstagramIcon from '@material-ui/icons/Instagram'
import { FaFacebook, FaInstagram, FaFlickr } from 'react-icons/fa'

const company = 'Renata Scarpelli - Design para Festas'

const Master = props => {

  const useStyles = makeStyles(theme => ({
    appBar: {
      borderBottom: `1px solid #000`,
      backgroundColor: `#000`,
      paddingBottom: '15px',
      paddingTop: '15px'
    },
    center: {
      textAlign: 'center',
      width: '100%'
    },
    right: {
      textAlign: 'right',
      width: '100%'
    },
    image: {
      width: '200px'
    },
    imageOnMediaQuery: {
      width: '100%'
    },
    footer: {
      '& h4': {
        color: `#FFF`,
      },
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      backgroundColor: `#222`,
      '& a': {
        textDecoration: 'none',
        color: `#FFF`
      }
    },
    iconFooter: {
      fontSize: '2.5rem',
      paddingRight:'5px'
    },
  }));

  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.center}>
            <img src='/renata-scarpelli.png' title={company} alt={company} className={classes.image} />
          </Typography>
        </Toolbar>
      </AppBar>
      {props.children}
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <img src='/renata-scarpelli.png' title={company} alt={company} className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.right}>
              <a href="https://facebook.com/renatascarpellidesigner" target="_blank" rel="nofollow noreferrer">
                <FaFacebook className={classes.iconFooter} />
              </a>
              <a href="https://www.instagram.com/renatascarpellidesigner/" target="_blank" rel="nofollow noreferrer">
                <FaInstagram className={classes.iconFooter} />
              </a>
              <a href="https://www.flickr.com/photos/187546490@N06/albums" target="_blank" rel="nofollow noreferrer">
                <FaFlickr className={classes.iconFooter} />
              </a>
            </Grid>
          </Grid>
          <div className={classes.center}>
            <p>Copyright &copy; {new Date().getFullYear() === 2020 ? `${new Date().getFullYear()}` : `2020 / ${new Date().getFullYear()}`} - {process.env.SITE_NAME}. Todos os direitos reservados<br />Desenvolvido por <a href="https://www.clicksolucoesweb.com.br" target="_blank" rel="nofollow noreferrer">Click Soluções Web</a></p>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Master